import config from '@/config';
import type { SearchItem, SearchProvider } from '../types';

export const bingSearchProvider: SearchProvider = async (
  query,
  projectName = 'enercity',
  scope = 'global'
) => {
  const url = new URL(`${config.apiBaseUrl}/search`);
  url.searchParams.append('q', query);
  url.searchParams.append('projectName', projectName);
  url.searchParams.append('scope', scope);

  const response = await fetch(url);

  if (!response.ok) {
    throw await response.json();
  }

  const data: { results?: SearchItem[] } = await response.json();

  return data?.results ?? [];
};
